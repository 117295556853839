<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="col-md-6">
                                <v-text-field
                                    v-model="name"
                                    :rules="name_rules"
                                    label="Tên"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    v-model="description"
                                    label="Mô tả"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-select
                                    v-model="parent_cate_id"
                                    :items="parent_categories"
                                    label="Parent Category"
                                    item-text="Name"
                                    item-value="Id"
                                ></v-select>
                            </div>

                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-9">
                                        <v-text-field
                                            v-model="thumbnail"
                                            label="Hình ảnh (Sử dụng ảnh vuông)"
                                            required
                                            :append-icon="'mdi-paperclip'"
                                            @click:append="browse_file"
                                        ></v-text-field>
                                    </div>
                                    <div v-if="thumbnail" class="col-md-3">
                                        <img :src="thumbnail" style="width: 50px; height: 50px" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    type="number"
                                    v-model="ordinal"
                                    label="Ordinal"
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-switch v-model="status" :label="status ? 'Hiện' : 'Ẩn'"></v-switch>
                            </div>

                            <input type="file" ref="upload" @change="upload_server" style="display:none;">

                            
                            <div class="col-md-6">
                                <v-btn
                                    class="ma-2 float-right"
                                    :loading="loading"
                                    color="info"
                                    @click="create_caterory"
                                >
                                    Thêm
                                </v-btn>

                                <v-btn
                                    class="ma-2 float-right"
                                    :loading="loading"
                                    @click="cancel"
                                >
                                    Huỷ
                                </v-btn>
                            </div>

                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Users'
    },
    data() {
        return {
            valid: true,
            name: "",
            description: "",
            thumbnail: "",
            status: true,
            parent_cate_id: 0,
            ordinal: 0,
            loading: false,
            name_rules: [],
        };
    },
    watch: {
        
    },
    computed: {
        categories() {
            return this.$store.getters['categories/get_categories'];
        },
        parent_categories() {
            var server_categories = this.$store.getters['categories/get_categories'];
            var data = [];
            for(var i in server_categories) {
                if(!server_categories[i].Parent_cate) {
                    data.push(server_categories[i]);
                }
            }
            return data;
        },
    },
    components: {
        
    },
    methods: {
        create_caterory() {
            this.loading = true;
            var payload = {
                Name: this.name,
                Description: this.description,
                Thumbnail: this.thumbnail,
                Parent_cate_id: this.parent_cate_id || 0,
                Status: this.status === true ? 1 : 0,
                Ordinal: this.ordinal ? parseInt(this.ordinal) : 0,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('categories/create_category', payload).then(() => {
                this.loading = false;
                this.$router.push({ name: 'categories-index' });
            }).catch(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.$router.push({ name: 'categories-index' });
        },
        browse_file() {
            this.$refs.upload.click();
        },
        upload_server() {
            var self = this;
            this.$store.dispatch('uploads/upload', {file: this.$refs.upload.files[0]}).then(res => {
                self.thumbnail = res.data.url;
            }).catch(e => {
                console.log(e);
            });
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Category", route: "index" }, { title: "Thêm category" } ]);
        this.$store.dispatch('categories/get_all');
    }
};
</script>



